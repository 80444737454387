export const possibleBrands = [
  { value: "essence", label: "Essence" },
  { value: "catrice", label: "Catrice" },
  { value: "chanel", label: "Chanel" },
  { value: "kiko milano", label: "Kiko Milano" },
  { value: "zaron true", label: "Zaron True" },
  { value: "lancôme", label: "Lancôme" },
  { value: "dior", label: "Dior" },
  { value: "neonail", label: "Neonail" },
  { value: "guerlain", label: "Guerlain" },
  { value: "nyx", label: "NYX" },
  { value: "gucci", label: "Gucci" },
  { value: "kylie cosmetics", label: "Kylie Cosmetics" },
  { value: "deborah lippmann", label: "Deborah Lippmann" },
  { value: "maison mirage", label: "Maison Mirage" },
  { value: "opi", label: "OPI" },
  { value: "gitti", label: "Gitti" },
  { value: "make up for ever", label: "Make Up For Ever" },
  { value: "nars", label: "NARS" },
  { value: "bobbi brown", label: "Bobbi Brown" },
  { value: "inglot", label: "Inglot" },
  { value: "l’oréal", label: "L’Oréal" },
  { value: "charlotte tilbury", label: "Charlotte Tilbury" },
  { value: "mac", label: "MAC" },
  { value: "herome", label: "Herome" },
  { value: "artdeco", label: "Artdeco" },
  { value: "essie", label: "Essie" },
  { value: "sephora collection", label: "Sephora Collection" },
  { value: "dear dahlia", label: "Dear Dahlia" },
  { value: "smashbox", label: "Smashbox" },
  { value: "fenty beauty", label: "Fenty Beauty" },
  { value: "inika organic", label: "Inika Organic" },
  { value: "urban decay", label: "Urban Decay" },
  { value: "trend !t up", label: "Trend !t Up" },
  { value: "l'oréal paris", label: "L'Oréal Paris" },
  { value: "estée lauder", label: "Estée Lauder" },
  { value: "wet n wild", label: "Wet n Wild" },
  { value: "giorgio armani", label: "Giorgio Armani" },
  { value: "nails.inc", label: "Nails.Inc" },
  { value: "jeffree star cosmetics", label: "Jeffree Star Cosmetics" },
  { value: "haus labs", label: "Haus Labs" },
  { value: "manhattan", label: "Manhattan" },
  { value: "luvia", label: "Luvia" },
  { value: "maybelline new york", label: "Maybelline New York" },
  { value: "honest", label: "Honest" },
  { value: "fenty beauty by rihanna", label: "Fenty Beauty by Rihanna" },
  { value: "anny", label: "Anny" },
  { value: "sigma beauty", label: "Sigma Beauty" },
  { value: "lime crime", label: "Lime Crime" },
  { value: "korres", label: "Korres" },
  { value: "la roche-posay", label: "La Roche-Posay" },
  { value: "alessandro", label: "Alessandro" },
  { value: "clarins", label: "Clarins" },
  { value: "rare beauty", label: "Rare Beauty" },
  { value: "vapour beauty", label: "Vapour Beauty" },
  { value: "buxom", label: "Buxom" },
  { value: "lavera naturkosmetik", label: "Lavera Naturkosmetik" },
  { value: "shiseido", label: "Shiseido" },
  { value: "micro cell", label: "Micro Cell" },
  { value: "kylie cosmetics ", label: "Kylie Cosmetics" },
  { value: "nyx professional makeup", label: "NYX Professional Makeup" },
  { value: "bellápierre", label: "Bellápierre" },
  { value: "laura mercier", label: "Laura Mercier" },
  { value: "t.leclerc", label: "T.Leclerc" },
  { value: "shiseido - ginza tokyo", label: "Shiseido - Ginza Tokyo" },
  { value: "rival", label: "Rival" },
  { value: "sleek", label: "Sleek" },
  { value: "misslyn", label: "Misslyn" },
  { value: "absolute new york", label: "Absolute New York" },
  { value: "clinique", label: "Clinique" },
  { value: "max factor", label: "Max Factor" },
  { value: "givenchy", label: "Givenchy" },
  { value: "morgan taylor", label: "Morgan Taylor" },
  { value: "maybelline ny", label: "Maybelline NY" },
  { value: "manucurist paris", label: "Manucurist Paris" },
  { value: "faby", label: "Faby" },
  { value: "rival de loop", label: "Rival de Loop" },
  { value: "huda beauty", label: "Huda Beauty" },
  { value: "e.l.f. cosmetics", label: "E.L.F. Cosmetics" },
  { value: "l`oreal paris ", label: "L`Oreal Paris" },
  { value: "delfy", label: "Delfy" },
  { value: "yves saint laurent", label: "Yves Saint Laurent" },
  { value: "lipstick queen", label: "Lipstick Queen" },
  { value: "ere perez", label: "Ere Perez" },
  { value: "nyx - professional makeup", label: "NYX - Professional Makeup" },
  { value: "make up forever", label: "Make Up Forever" },
  { value: "lanature", label: "Lanature" },
  { value: "kvd beauty", label: "KVD Beauty" },
  { value: "kjaer weis", label: "Kjaer Weis" },
  { value: "kryolan", label: "Kryolan" },
  { value: "nudestix", label: "Nudestix" },
  { value: "eisenberg", label: "Eisenberg" },
  { value: "tarte", label: "Tarte" },
  { value: "revolution beauty london", label: "Revolution Beauty London" },
  { value: "dior addict", label: "Dior Addict" },
  { value: "babor", label: "Babor" },
  { value: "be+radiance cucumber", label: "Be+Radiance Cucumber" },
  { value: "alverde naturkosmetik", label: "Alverde Naturkosmetik" },
  { value: "néonail", label: "Néonail" },
  { value: "glossier.", label: "Glossier." },
  { value: "too faced", label: "Too Faced" },
  { value: "glossworks", label: "Glossworks" },
  { value: "l´oreal", label: "L´Oreal" },
  { value: "glossier", label: "Glossier" },
  { value: "edding", label: "Edding" },
  { value: "lavera", label: "Lavera" },
  { value: "loreal", label: "Loreal" },
  { value: "ilia", label: "Ilia" },
  { value: "kia-charlotta", label: "Kia-Charlotta" },
  { value: "logona", label: "Logona" },
  { value: "vogue", label: "Vogue" },
  { value: "sally hansen", label: "Sally Hansen" },
  { value: "tom ford", label: "Tom Ford" },
  { value: "diego dalla palma", label: "Diego Dalla Palma" },
  { value: "l´oreal paris", label: "L´Oreal Paris" },
  { value: "estee lauder", label: "Estee Lauder" },
  { value: "pat mcgrath labs", label: "Pat McGrath Labs" },
  { value: "lia", label: "Lia" },
  { value: "sacha", label: "Sacha" },
  { value: "l.a girl", label: "L.A Girl" },
  { value: "benecos", label: "Benecos" },
  { value: "dolce&gabbana", label: "Dolce&Gabbana" },
  { value: "isadora", label: "Isadora" },
  { value: "revolution pro", label: "Revolution Pro" },
  { value: "cover / fx", label: "Cover / FX" },
  { value: "black opal", label: "Black Opal" },
  { value: "alverde", label: "Alverde" },
  { value: "charlotte tilburry", label: "Charlotte Tilburry" },
  { value: "e.l.f.", label: "E.L.F." },
  { value: "black|up", label: "Black|Up" },
  { value: "jimmy choo", label: "Jimmy Choo" },
  { value: "lord & berry", label: "Lord & Berry" },
  { value: "rimmel london", label: "Rimmel London" },
  { value: "nyx professional makeup ", label: "NYX Professional Makeup" },
  { value: "dolce&gabanna", label: "Dolce&Gabanna" },
  { value: "anastasia berverly hills", label: "Anastasia Berverly Hills" },
  { value: "anastasia beverly hills", label: "Anastasia Beverly Hills" },
  { value: "mavala", label: "Mavala" },
  { value: "l\\'oreal paris", label: "L\\'Oreal Paris" },
  { value: "manucurist", label: "Manucurist" },
  { value: "haus labs by lady gaga", label: "Haus Labs by Lady Gaga" },
  { value: "carslan", label: "Carslan" },
  { value: "absolution", label: "Absolution" },
  { value: "maybelline - new york ", label: "Maybelline - New York" },
  {
    value: "wyn beauty by serena williams",
    label: "Wyn Beauty by Serena Williams",
  },
  { value: "r.e.m. beauty", label: "R.E.M. Beauty" },
  { value: "lavera ", label: "Lavera" },
  { value: "sheglam", label: "Sheglam" },
  { value: "morphe ", label: "Morphe" },
  { value: "bell hypoallergenic", label: "Bell Hypoallergenic" },
  { value: "nailberry", label: "Nailberry" },
  { value: "3ina", label: "3INA" },
  { value: "alcina", label: "Alcina" },
  { value: "collistar", label: "Collistar" },
  { value: "bourjois", label: "Bourjois" },
  { value: "maybelline", label: "Maybelline" },
  { value: "dior ", label: "Dior" },
  { value: "dior - backstage", label: "Dior - Backstage" },
  { value: "zao", label: "Zao" },
  { value: "idun minerals", label: "Idun Minerals" },
  { value: "kylie jenner", label: "Kylie Jenner" },
  { value: "maybellin ny", label: "Maybellin NY" },
  { value: "thebalm", label: "TheBalm" },
  { value: "no_entry", label: "No Entry" },
  { value: "delilah", label: "Delilah" },
  { value: "tower 28 beauty", label: "Tower 28 Beauty" },
  { value: "physicians formula", label: "Physicians Formula" },
  { value: "laneige", label: "Laneige" },
  { value: "makeup by mario", label: "Makeup by Mario" },
  { value: "beauty made easy le papier", label: "Beauty Made Easy Le Papier" },
  { value: "jovina cosmetics", label: "Jovina Cosmetics" },
  { value: "revlon", label: "Revlon" },
  { value: "hynt beauty", label: "Hynt Beauty" },
  { value: "mistine", label: "Mistine" },
  {
    value: "revolution makeup revolution london ",
    label: "Revolution Makeup Revolution London",
  },
  { value: "zoeva", label: "Zoeva" },
  { value: "lakme", label: "Lakme" },
  { value: "kryolan ", label: "Kryolan" },
  { value: "kia charlotta", label: "Kia Charlotta" },
  { value: "dolce&gabannna", label: "Dolce&Gabannna" },
  { value: "youth foria", label: "Youth Foria" },
  { value: "sante", label: "Sante" },
  { value: "elizabeth arden new york", label: "Elizabeth Arden New York" },
  { value: "illamasqua", label: "Illamasqua" },
  { value: "maybelline - new york", label: "Maybelline - New York" },
  { value: "summer fridays", label: "Summer Fridays" },
  { value: "armani", label: "Armani" },
  { value: "rimmel  london", label: "Rimmel  London" },
  { value: "r.e.m beauty", label: "R.E.M Beauty" },
  { value: "etienne", label: "Etienne" },
  { value: "londontown", label: "Londontown" },
  { value: "stagecolor", label: "Stagecolor" },
  { value: "l\\'oréal", label: "L\\'Oréal" },
  { value: "mádara", label: "Mádara" },
  { value: "y.o.u", label: "Y.O.U" },
  { value: "nip+fab", label: "Nip+Fab" },
  { value: "artdeco ", label: "Artdeco" },
  { value: "paese", label: "Paese" },
  { value: "morphe", label: "Morphe" },
  { value: "cover fx", label: "Cover FX" },
  { value: "tarte ", label: "Tarte" },
  { value: "ciaté london", label: "Ciaté London" },
  { value: "herzlack", label: "Herzlack" },
  { value: "jean & len", label: "Jean & Len" },
  { value: "by terry", label: "By Terry" },
  { value: "the sign tribe", label: "The Sign Tribe" },
  { value: "zarko beauty by oli", label: "Zarko Beauty by Oli" },
  { value: "rival ", label: "Rival" },
  { value: "t. leclerc", label: "T. Leclerc" },
  { value: "ozn", label: "OZN" },
  { value: "velvetines", label: "Velvetines" },
  { value: "wardah", label: "Wardah" },
  { value: "ash cosmetics", label: "Ash Cosmetics" },
  { value: "anastasia beverly hills ", label: "Anastasia Beverly Hills" },
  { value: "skintific", label: "Skintific" },
  { value: "trend it up", label: "Trend It Up" },
  { value: "bareminerals", label: "BareMinerals" },
  { value: "uslu airlines", label: "Uslu Airlines" },
  { value: "laura mercier paris ny", label: "Laura Mercier Paris NY" },
  { value: "tom ford soleil", label: "Tom Ford Soleil" },
  { value: "doucce", label: "Doucce" },
  { value: "kylie jenner ", label: "Kylie Jenner" },
  { value: "maybelline new york ", label: "Maybelline New York" },
  { value: "strong", label: "Strong" },
  { value: "glossier. ", label: "Glossier." },
  { value: "urban declay", label: "Urban Declay" },
  { value: "kiko - milano", label: "Kiko - Milano" },
  { value: "beter nail care", label: "Beter Nail Care" },
  { value: "rodial", label: "Rodial" },
  { value: "youth floria", label: "Youth Floria" },
  { value: "l´oréal paris", label: "L´Oréal Paris" },
  { value: "pixi", label: "Pixi" },
  { value: "lancome paris", label: "Lancome Paris" },
  { value: "laura geller", label: "Laura Geller" },
  { value: "medipharma cosmetics", label: "Medipharma Cosmetics" },
  { value: "gosh copenhagen", label: "gosh copenhagen" },
  { value: "sephora", label: "Sephora" },
  { value: "fenty beauty ", label: "Fenty Beauty " },
  { value: "lancome", label: "Lancome" },
  { value: "neonnail", label: "Neonnail" },
  { value: "l`oreal paris", label: "L`oreal paris" },
  { value: "neonnail", label: "Neonnail" },
  { value: "lavera - naturkosmetik", label: "Lavera - Naturkosmetik" },
  { value: "avant", label: "Avant" },
  { value: "lòreal", label: "Lòreal" },
  { value: "kester black", label: "Kester Black" },
];
